import React, { useState, useEffect } from 'react'
import {  useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col, Table} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import Loader from "../components/Loader"
import Message from "../components/Message"
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { listMyOrders } from '../actions/orderActions'
import moment from 'moment'

function ProfileScreen() {

  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ message, setMessage ] = useState('')

  const dispatch = useDispatch()
  let navigate = useNavigate();

  const userDetails = useSelector(state => state.userDetails)
  const {error, loading, user} = userDetails

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector(state => state.userUpdateProfile)
  const { success } = userUpdateProfile
  

  const orderListMy = useSelector(state => state.orderListMy)
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy

  useEffect(() => {
      if (!userInfo) {
        navigate('/login')  //go back to previous page if we are logged in already
      } else {
        if( !user || !user.name || success || userInfo.id !== user.id){
          dispatch({type: USER_UPDATE_PROFILE_RESET})
          dispatch(getUserDetails('profile'))
          dispatch(listMyOrders())
        } else {
          setName(user.name)
          setEmail(user.email)
        }
      }


  }, [dispatch, navigate, userInfo, user, success])

  const submitHandler = (e) => {
    e.preventDefault()

    if(password !== confirmPassword){
      setMessage('Passwords does not match')
    } else {
      dispatch(updateUserProfile({ 
        'id': user.id,
        'name': name,
        'email': email,
        'password': password
      
      }))
      setMessage('')
    }

    
  }


  return (
    <Row>
      <Col md={3}>
        <h2>User Profile</h2>
        {message && <Message variant='danger'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>

        <Form.Group controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            required
            type='name'
            placeholder='Enter Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          >
          </Form.Control>
        </Form.Group>
        <br></br>
        <Form.Group controlId='email'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type='email'
            placeholder='Enter Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          >
          </Form.Control>
        </Form.Group>
        <br></br>

        <Form.Group controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          >
          </Form.Control>
        </Form.Group>
        <br></br>        

        <Form.Group controlId='passwordConfirm'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Confirm Password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          >
          </Form.Control>
        </Form.Group>
        <br></br>        


        <Button type='submit' variant='primary'>
          Update
        </Button>

        
      </Form>
      </Col>

      <Col md={9}>
        <h2>My Orders</h2>
        {
          loadingOrders ? (
            <Loader />
          ) : errorOrders ? (
            <Message variant='danger'>{errorOrders}</Message>
          ) : (
            <Table striped responsive className='table-sm'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Total</th>
                  <th>Paid</th>
                  <th>Delivered</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {
                  orders.map(order => (
                    <tr key={order._id}>
                      <td>{order._id}</td>
                      <td>{moment(order.deliveredAt).format('M/D/YYYY')}</td>
                      <td>${order.totalPrice}</td>
                      <td>{
                            order.isPaid ?
                            moment(order.paidAt).format('M/D/YYYY') : 
                            (<i className='fas fa-times' style={{ color: 'red'}}></i>)
                          }
                      </td>
                      <td>
                        <LinkContainer to={`/orders/${order._id}`}>
                          <Button className='btn-sm'>Details</Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))
                }
                
              </tbody>
            </Table>
          )

        }
      </Col>

    </Row>
  )
}

export default ProfileScreen