import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import Loader from "../components/Loader"
import Message from "../components/Message"
import FormContainer from "../components/FormContainer"
import { login } from '../actions/userActions'

const LoginScreen = () => {
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')

  const dispatch = useDispatch()

  let navigate = useNavigate();

  const userLogin = useSelector(state => state.userLogin)

  const {error, loading, userInfo} = userLogin

  useEffect(() => {
      if (userInfo) {
        navigate('/')  //go back to home page if we are logged in already
      }
  }, [userInfo, navigate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <FormContainer>
      <h1>Sign In</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>

        <Form.Group controlId='email'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          >
          </Form.Control>
        </Form.Group>
        <br></br>

        <Form.Group controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          >
          </Form.Control>
        </Form.Group>
        <br></br>

        <Button type='submit' variant='primary'>
          Sign In  
        </Button>

      </Form>

      <Row className='py-3'>
        <Col>
          New Customer? &nbsp; 
          <Link to={'/register'}>
            Register
          </Link>
        </Col>
      </Row>

    </FormContainer>
  )
}

export default LoginScreen